<template>
  <div class="h-full flex flex-col">
    <h6 class="mb-10">
      Verify with Mobile App
    </h6>

    <div class="rounded-sm bg-onboarding-900 text-white flex flex-row p-3">
      <div class="rounded-full h-6 w-6 bg-gtb"></div>
      <p class="text-10 mt-1 ml-3">
        Follow the steps below to verify your account.
      </p>
    </div>

    <gtb-mobile class="mb-6" />

    <template v-if="getFormError(form)">
      <div class="alert alert-red-soft mb-10">
        <span class="alert-icon">!</span>
        <span>{{ getFormError(form) }}</span>
      </div>
    </template>

    <div class="flex gap-4 mt-auto">
      <button
        type="button"
        class="btn btn-gray-soft btn-md relative"
        :disabled="form.loading"
        @click="back"
      >
        <span>Back</span>
        <i class="absolute inline-flex left-20px top-1/2" style="transform: translateY(-50%);">
          <ion-icon name="arrow-back-outline" />
        </i>
      </button>
      <button
        type="button"
        class="btn btn-blue btn-md relative w-full"
        :disabled="form.loading || isVerified"
        @click.prevent="submit"
      >
        <sm-loader v-if="form.loading" class="sm-loader-white" />
        <span v-else>I've done it</span>
        <i class="absolute inline-flex right-20px top-1/2" style="transform: translateY(-50%);">
          <ion-icon name="arrow-forward-outline" />
        </i>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'MobileForm',
  props: {
    isVerified: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    GtbMobile: () => import("@/components/forms/GtbMobile.vue"),
  },
  data() {
    return {
      form: this.$options.basicForm([]),
    };
  },
  methods: {
    ...mapActions('session', ['updateUser']),
    back() {
      this.$emit('back');
    },
    async submit() {
      this.form.error = false;
      this.form.setLoading();

      await this.sendRequest("personal.onboarding.documents.mobile", {
        success: (response) => {
          const { data: { user, documents } } = response.data;
          this.updateUser({ ...user, documents })
            .then(() => {
              this.$emit("verify-mobile", { user, documents });
            });
        },
        error: (error) => {
          this.form.error = error;
        },
      });

      this.form.setLoading(false);
    },
  },
};
</script>
